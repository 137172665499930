const chainConfig = {
  coins: ["regen", "secret", "cosmos"],
  regen: {
    title: "Regen",
    prefix: "regen",
    valPrefix: "regenvaloper",
    chainId: "regen-1",
    type: "stargate",
    ticker: "REGEN",
    denom: "uregen",
    rpc: "https://regen.stakesystems.io:2053/",
    validators: ["regenvaloper15khamenky6kmmv8npd0mnwme22fhks735zdpk6"],
    decimals: 6,
  },
  secret: {
    title: "Secret",
    prefix: "secret",
    valPrefix: "secretvaloper",
    chainId: "secret-4",
    type: "stargate",
    ticker: "SCRT",
    denom: "uscrt",
    rest: "https://lcd-secret.keplr.app",
    rpc: "https://rpc-secret.scrtlabs.com/secret-4/rpc/",
    validators: ["secretvaloper19gaqfv4zc76mkklvuql4p7jjwuw5w0604sej43"],
    decimals: 6,
  },
  cosmos: {
    title: "Cosmos",
    prefix: "cosmos",
    valPrefix: "cosmosvaloper",
    chainId: "cosmoshub-4",
    type: "stargate",
    ticker: "ATOM",
    denom: "uatom",
    rest: "https://api.cosmoshub.pupmos.network",
    rpc: "https://rpc.cosmoshub.pupmos.network",
    validators: ["cosmosvaloper14upntdx8lf0f49t987mj99zksxnluanvu6x4lu"],
    decimals: 6,
  },
};

export default chainConfig;
