const RedelegateForm = (
  loading,
  error,
  accounts,
  srcValidator,
  dstValidator,
  address,
  amount,
  coin,
  chainConfig,
  bond,
  initChain,
  updateSrcValidator,
  updateAmount,
  selectAddress,
  selectValidator,
  switchModes
) => {
  return (
    <div className="form-container">
      {/* Select chain from the options in the chain config */}

      <label>Project</label>
      <select
        disabled={loading}
        onChange={(evt) => initChain(evt.target.value)}
        className="select-style"
      >
        {!coin && (
          <option value={false} key={"default"}>
            {loading ? "" : "Select a project..."}
          </option>
        )}
        {chainConfig.coins.map((chain) => {
          return (
            <option value={chain} key={chain}>
              {chainConfig[chain].title}
            </option>
          );
        })}
      </select>

      {/* Enter the source node id as a string */}

      <label>Source Validator ID</label>
      <input
        className="input-style"
        onChange={(evt) => updateSrcValidator(evt.target.value)}
        placeholder={chainConfig[coin] && `${chainConfig[coin].valPrefix}...`}
        value={srcValidator ? srcValidator : ""}
      />

      {/* Enter the destination node id as a string */}

      <label>
        Destination Validator ID
        <small
          onClick={
            chainConfig[coin]
              ? (evt) => selectValidator(chainConfig[coin].validators[0])
              : () => null
          }
          style={{
            cursor: chainConfig[coin] ? "pointer" : "inherit",
            color: chainConfig[coin] ? "white" : "inherit",
          }}
        >
          Use Republic
        </small>
      </label>
      <input
        className="input-style"
        onChange={(evt) => selectValidator(evt.target.value)}
        placeholder={chainConfig[coin] && `${chainConfig[coin].valPrefix}...`}
        value={dstValidator ? dstValidator : ""}
      />

      {/* Select delegator address from the list keplr provides */}

      <label>Delegator Address</label>
      <select
        value={address}
        onChange={(evt) => selectAddress(evt.target.value)}
        className="select-style"
      >
        {accounts.map((account) => {
          return (
            <option value={account.address} key={account.address}>
              {account.address}
            </option>
          );
        })}
      </select>

      {/* Enter the amount to stake */}

      <label>Stake Amount</label>
      <input
        className="input-style"
        onChange={(evt) => updateAmount(evt.target.value)}
        placeholder={chainConfig[coin] && chainConfig[coin].ticker}
        value={amount}
      />

      {/* Deploy button */}

      <button
        className="stake-button"
        onClick={!error ? () => bond() : () => window.location.reload()}
      >
        {!loading ? (
          "Redelegate"
        ) : error ? (
          "Connect"
        ) : (
          <div className="lds-ring">
            <div />
          </div>
        )}
      </button>

      {/* Switch to delegation */}

      <div className="help">
        <a
          onClick={() => switchModes("stake")}
          className="switch-modes"
          style={{ opacity: loading ? 0.5 : 1 }}
        >
          Not staking yet? <span style={{ color: "white" }}>Delegate</span>
        </a>
      </div>
    </div>
  );
};

export default RedelegateForm;
