import "./App.css";
import { Component } from "react";
import keplrInit from "./scripts/keplrInit";
import chainConfig from "./scripts/chainConfig";
import StakeForm from "./components/StakeForm";
import RedelegateForm from "./components/RedelegateForm";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      mode: "stake",
      error: false,
      staking: true,
      coin: false,
      address: false,
      srcValidator: "",
      dstValidator: "",
      accounts: [],
      amount: "",
    };
  }

  // React equivelant to on load
  componentDidMount = () => {
    window.onload = async () => {
      if (!window.getOfflineSigner || !window.keplr) {
        this.setState({ error: true });
        alert("Keplr extension not found");
      } else {
        // Force stake.js in to a different chunk as libsodium is massive
        import("./scripts/stake").then((stake) => {
          this.setState({ loading: false });
        });
      }
    };
  };

  // Switch modes
  switchModes = (value) => {
    this.setState({ mode: value });
  };

  // Ask Keplr to ask the user to authorise the chain and clear data if changed
  initChain = (coin) => {
    this.setState(
      {
        staking: true,
        coin: coin,
        srcValidator: "",
        dstValidator: "",
        accounts: [],
        amount: "",
      },
      () => {
        keplrInit(this.accountCallback, chainConfig[coin]);
      }
    );
  };

  // Get account addresses called back from KeplrInit
  accountCallback = (accounts) => {
    this.setState({ accounts, address: accounts[0].address });
  };

  // Update delegator address on change
  setSrcValidator = (value) => {
    this.setState({ srcValidator: value });
  };

  // Update delegator address on change
  setAddress = (value) => {
    this.setState({ address: value });
  };

  // Update validator address on change
  setDstValidator = (value) => {
    this.setState({ dstValidator: value });
  };

  // Update stake amount on change
  updateAmount = (value) => {
    !isNaN(value) || !value
      ? this.setState({ amount: value })
      : alert("Amount can only be a number");
  };

  // Run the bonding script
  bond = () => {
    if (
      this.state.amount === "" ||
      isNaN(this.state.amount) ||
      !this.state.address ||
      !this.state.dstValidator
    ) {
      alert("Please check your input data");
    } else {
      this.setState({ loading: true });
      import("./scripts/stake").then((stake) => {
        stake.stake(
          chainConfig[this.state.coin],
          this.state.dstValidator,
          this.state.address,
          Math.floor(
            this.state.amount *
              Math.pow(10, chainConfig[this.state.coin].decimals)
          ),
          this.bondCallback
        );
      });
    }
  };

  redelegate = () => {
    if (
      this.state.amount === "" ||
      isNaN(this.state.amount) ||
      !this.state.address ||
      !this.state.dstValidator
    ) {
      alert("Please check your input data");
    } else {
      this.setState({ loading: true });
      import("./scripts/redelegate").then((stake) => {
        stake.stake(
          chainConfig[this.state.coin],
          this.state.srcValidator,
          this.state.dstValidator,
          this.state.address,
          Math.floor(
            this.state.amount *
              Math.pow(10, chainConfig[this.state.coin].decimals)
          ),
          this.bondCallback
        );
      });
    }
  };

  // Update stake amount on change
  bondCallback = (result) => {
    result && this.setState({ amount: "" });
    this.setState({ loading: false });
  };

  render() {
    return (
      <div className="app">
        {/* not sure why the entire app is in a header, this is the default from create-react-app layout */}
        <header className="app-header">
          <div className="stake-card">
            {this.state.mode === "stake"
              ? StakeForm(
                  this.state.loading,
                  this.state.error,
                  this.state.accounts,
                  this.state.address,
                  this.state.dstValidator,
                  this.state.amount,
                  this.state.coin,
                  chainConfig,
                  this.bond,
                  this.initChain,
                  this.updateAmount,
                  this.setAddress,
                  this.setDstValidator,
                  this.switchModes
                )
              : RedelegateForm(
                  this.state.loading,
                  this.state.error,
                  this.state.accounts,
                  this.state.srcValidator,
                  this.state.dstValidator,
                  this.state.address,
                  this.state.amount,
                  this.state.coin,
                  chainConfig,
                  this.redelegate,
                  this.initChain,
                  this.setSrcValidator,
                  this.updateAmount,
                  this.setAddress,
                  this.setDstValidator,
                  this.switchModes
                )}
          </div>
        </header>
      </div>
    );
  }
}

export default App;
